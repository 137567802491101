body {
  background: linear-gradient(135deg, #181818, #1b1b3a);
  background-size: 400% 400%;
  animation: gradient-animation 15s ease infinite;
  color: #ffffff;
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  position: relative; /* Added to help position blob and wave correctly */
  overflow: hidden; /* Prevent overflow issues due to animations */
}

h2, h3 {
  color: #5a57ff;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.main-content {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.container {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  justify-content: space-around;
}

.side-card {
  flex: 1 1 30%;
  min-width: 250px;
}

.center-card {
  flex: 1 1 35%;
  min-width: 300px;
}

.card {
  background-color: #242424;
  padding: 30px;
  border-radius: 10px;
  border: 2px solid #6c1cff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-header {
  color: #5a57ff;
  font-size: 1.4em;
  margin-bottom: 15px;
  text-align: center;
}

.card-content {
  flex-grow: 1;
}

.card-content ul {
  list-style-type: disc;
  padding-left: 40px;
  text-align: left;
  margin-top: 0;
}

.card-content ul li {
  margin-bottom: 10px;
  color: #bfbfbf;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .card {
    width: 100%;
  }
}

.contact-section,
.education-section {
  background-color: #242424;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 2px solid #6c1cff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
}

.education-section,
.now-section,
.header-section,
.experience-section,
.about-section {
  background-color: #242424;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 2px solid #6c1cff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 300px;
}

.header-section {
  padding: 10px;
  max-width: 1000px;
  margin: 0 auto;
  height: 150px;
}

.small-header {
  max-width: 1000px;
  padding: 10px;
  height: 150px;
}

.header-section h2 {
  color: #5a57ff;
}

.social-links {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.social-links a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 2px solid #5a57ff;
  border-radius: 50%;
  margin: 0 10px;
  color: #ffffff;
  text-decoration: none;
  font-size: 20px;
  transition: all 0.3s ease;
}

.social-links a:hover {
  border-color: #ffffff;
  color: #5a57ff;
  transform: scale(1.1);
}

.status-indicator {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.active-status {
  width: 12px;
  height: 12px;
  background-color: #00ff00;
  border-radius: 50%;
  margin-right: 10px;
}

/* #### Highlighted for cropping adjustments #### */
.profile-picture {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top; 
  margin: 10px auto;
  border: 3px solid #6c1cff;
}

/* Added Blob Animation */
@keyframes blob {
  0%, 100% {
    border-radius: 40% 60% 55% 45%;
  }
  50% {
    border-radius: 55% 45% 40% 60%;
  }
}

.blob {
  position: absolute;
  top: 20%;
  left: 20%;
  width: 300px;
  height: 300px;
  background: linear-gradient(135deg, #6c1cff, #5a57ff);
  animation: blob 10s infinite alternate;
  opacity: 0.7;
  z-index: -1;
  filter: blur(80px);
}

/* Added Wave Animation */
@keyframes wave {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 200%;
  height: 200px;
  background: url('https://www.svgrepo.com/show/201206/wave.svg') repeat-x;
  background-size: contain;
  animation: wave 15s linear infinite;
  z-index: -2;
}
/* Smooth, Gradual Darker Gradient Background */
@keyframes subtle-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

body {
  background: linear-gradient(135deg, #0f0f1a, #1a1a2b, #221e3b, #1a1a2b, #0f0f1a);
  background-size: 200% 200%;
  animation: subtle-gradient 240s ease infinite; /* Slow, continuous gradient shift */
}

/* Multiple Small Blob Animations - Faster Movement */
@keyframes smallBlob1 {
  0%, 100% {
    transform: translate(0, 0) scale(1);
  }
  50% {
    transform: translate(-50px, -50px) scale(1.2);
  }
}

@keyframes smallBlob2 {
  0%, 100% {
    transform: translate(0, 0) scale(1);
  }
  50% {
    transform: translate(50px, 50px) scale(1.1);
  }
}

@keyframes smallBlob3 {
  0%, 100% {
    transform: translate(0, 0) scale(1);
  }
  50% {
    transform: translate(-30px, 50px) scale(1.15);
  }
}

@keyframes smallBlob4 {
  0%, 100% {
    transform: translate(0, 0) scale(1);
  }
  50% {
    transform: translate(40px, -40px) scale(1.3);
  }
}

@keyframes smallBlob5 {
  0%, 100% {
    transform: translate(0, 0) scale(1);
  }
  50% {
    transform: translate(-40px, 30px) scale(1.1);
  }
}

@keyframes smallBlob6 {
  0%, 100% {
    transform: translate(0, 0) scale(1);
  }
  50% {
    transform: translate(30px, -30px) scale(1.25);
  }
}

@keyframes smallBlob7 {
  0%, 100% {
    transform: translate(0, 0) scale(1);
  }
  50% {
    transform: translate(-20px, 20px) scale(1.05);
  }
}

/* General Blob Styles - Increased Speed */
.small-blob {
  position: fixed;
  border-radius: 50%;
  opacity: 0.6; /* Maintain a subtle but visible appearance */
  z-index: -1;
  filter: blur(50px); /* Reduce blur to make the blobs more distinct */
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

/* Individual Blobs with Different Animations and Darker Colors */
.blob1 {
  top: 10%;
  left: 15%;
  width: 150px;
  height: 150px;
  background: linear-gradient(135deg, #4a148c, #6a1b9a); /* Dark violet gradient */
  animation-name: smallBlob1;
  animation-duration: 6s; /* Faster speed for more dynamic movement */
}

.blob2 {
  top: 50%;
  left: 60%;
  width: 200px;
  height: 200px;
  background: linear-gradient(135deg, #004d40, #00796b); /* Dark teal gradient */
  animation-name: smallBlob2;
  animation-duration: 7s; 
}

.blob3 {
  bottom: 20%;
  right: 10%;
  width: 180px;
  height: 180px;
  background: linear-gradient(135deg, #b71c1c, #880e4f); /* Deep red to dark pink */
  animation-name: smallBlob3;
  animation-duration: 5s; 
}

.blob4 {
  top: 30%;
  left: 70%;
  width: 130px;
  height: 130px;
  background: linear-gradient(135deg, #1b5e20, #2e7d32); /* Dark green gradient */
  animation-name: smallBlob4;
  animation-duration: 6s; 
}

.blob5 {
  bottom: 30%;
  left: 20%;
  width: 170px;
  height: 170px;
  background: linear-gradient(135deg, #283593, #3949ab); /* Dark blue gradient */
  animation-name: smallBlob5;
  animation-duration: 7s; /* Faster speed */
}

.blob6 {
  top: 15%;
  right: 25%;
  width: 140px;
  height: 140px;
  background: linear-gradient(135deg, #bf360c, #e65100); /* Dark orange gradient */
  animation-name: smallBlob6;
  animation-duration: 5s; 
}

.blob7 {
  bottom: 10%;
  left: 50%;
  width: 160px;
  height: 160px;
  background: linear-gradient(135deg, #3e2723, #5d4037); /* Dark brown gradient */
  animation-name: smallBlob7;
  animation-duration: 6s; /* Faster speed */
}

/* Full-Screen Wave Animation - Adjusted for Entire Screen Coverage */
@keyframes wave {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.wave {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 400%; /* Increased width to ensure full side-to-side coverage */
  height: 400px; /* Increased height for a more noticeable wave */
  background: url('https://www.svgrepo.com/show/201206/wave.svg') repeat-x;
  background-size: contain;
  animation: wave 25s linear infinite; /* Slow wave movement for a relaxing background animation */
  z-index: -2;
}

.header-section {
  padding: 10px; /* Added more padding for a neat look */
  max-width: 1200px; /* Set max-width to control text width */
  margin: 0 auto;
  height: auto; /* Remove fixed height */
}

.welcome-text {
  max-width: 700px;
  margin: 20px auto; 
  text-align: justify; 
  line-height: 1.6; 
  font-size: 1.1rem; 
}

.social-links {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 15px; /* Added gap to space out icons */
}
/* General Mobile Styles */
@media (max-width: 768px) {
  .container {
    flex-direction: column;  
    align-items: center;     
    gap: 15px;               
  }

  .card {
    width: 90%;              
    margin-bottom: 20px;     
  }

  .small-header {
    max-width: 90%;
    padding: 20px;
    height: auto;            
  }

  .profile-picture {
    width: 50px;             
    height: 50px;
  }

  h2, h3 {
    font-size: 1.4em;        /* Reduce heading sizes */
  }

  .social-links {
    gap: 15px;               /* Add space between icons */
  }

  .social-links a {
    width: 35px;             /* Smaller social icons */
    height: 35px;
    font-size: 18px;
  }
}

/* For Even Smaller Devices (Phones) */
@media (max-width: 480px) {
  .container {
    padding: 10px;           
  }

  .card {
    padding: 20px;           
  }

  h2, h3 {
    font-size: 1.2em;       
  }

  p {
    font-size: 0.9em;        
  }

  .social-links a {
    width: 30px;            
    height: 30px;
    font-size: 16px;
  }
}
.download-cv {
  margin-top: 20px;
  text-align: center;
}

.download-cv a {
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #5a57ff;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.download-cv a:hover {
  background-color: #3a1b6c;
}

.download-cv a svg {
  margin-right: 8px;
}
